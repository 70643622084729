<template>
  <div class="programLevel">
    <program-level
      v-on:cancelled="$emit('close')"
      v-on:confirmed="$emit('close')"
    />
  </div>
</template>

<script>
import ProgramLevel from '@/components/program/ProgramLevel';

export default {
  components: {
    ProgramLevel,
  },
};
</script>
